import React, { useEffect } from 'react'
import '../styles/globals.css'
import Head from 'next/head'
import { AppProps } from 'next/app'
import { useLocalStorage } from '@tumelo/common'
import {
  LoggerProvider,
  LoggerService,
  LoggerServiceConsole,
  LoggerServiceSentry,
  LoggerServiceCompound,
} from '@tumelo/logging'
import { SoftConfigThemeSchema, GlobalStyle } from '@tumelo/designsystem'
import { ThemeProvider } from 'styled-components'
import { ConfigContext, getConfig } from '../application/environment'
import { Auth } from '../application/auth'
import { ClientContext, getMockedOrNotMockedClient } from '../application/client'
import {
  ClientContext as ReportingClientContext,
  getMockedOrNotMockedReportingClient,
} from '../application/reportingClient'
import { AmplifyUserCtx, sampleUserContext, UserContext } from '../application/user'
import { Landing } from '../components/landing'
import { CookieBanner, cookieConsentKey, CookieConsentValue } from '../components/cookies'
import * as Analytics from '../application/analytics'
import { createAnalyticsMixpanel } from '../application/analytics/AnalyticsMixpanel'
import { FlagsContextProvider } from '../application/FlagsContext'
import { PlannedMaintenance } from '../components/PlannedMaintenance'

// get config at top level to ensure throwing an error during build if env vars are missing
getConfig()

export default function App({ Component, pageProps }: AppProps) {
  const [cookieConsent] = useLocalStorage(cookieConsentKey, CookieConsentValue.NotSet)
  const cookiesAccepted = cookieConsent === CookieConsentValue.Accepted
  const config = getConfig()
  const isProduction = config.appEnv === 'production'
  const userContext = config.clientType === 'mock' ? sampleUserContext() : AmplifyUserCtx()
  const getClient = getMockedOrNotMockedClient(config)
  const getReportingClient = getMockedOrNotMockedReportingClient(config)

  // configure logger service
  let logger: LoggerService = LoggerServiceConsole
  const dsn = 'https://73860044b4f04b5b98f5b8080f453ae0@o365838.ingest.sentry.io/5958341'
  const sentryLogger = new LoggerServiceSentry({ dsn, environment: config.appEnv })
  let mixpanelAnalytics = Analytics.useAnalytics()

  if (isProduction && cookiesAccepted) {
    logger = new LoggerServiceCompound(LoggerServiceConsole, sentryLogger)
    // Stewardship Platform Prod mixpanel
    mixpanelAnalytics = createAnalyticsMixpanel('9db3041cca89e463a1478854fb15b33d', {})
  }

  if (!isProduction && cookiesAccepted) {
    // Stewardship Platform Dev mixpanel
    mixpanelAnalytics = createAnalyticsMixpanel('7c74f88bbb9d027882b55ae2519c3af5', { debug: true, ignore_dnt: true })
  }

  useEffect(() => {
    const setUserId = async () => {
      const user = await userContext.getUserDetails()
      if (user === undefined) {
        logger.setUserData({})
      } else {
        logger.setUserData({ id: user.id })
      }
    }
    setUserId()
  }, [logger, userContext])

  if (config.plannedMaintenance) {
    return <PlannedMaintenance />
  }

  return (
    <>
      <Head>
        <title>Tumelo Stewardship Dashboard</title>
        <link rel="icon" href="/favicon-32x32.png" />
      </Head>
      <LoggerProvider logger={logger}>
        <Analytics.AnalyticsContext.Provider value={mixpanelAnalytics}>
          <ConfigContext.Provider value={config}>
            <ClientContext.Provider value={getClient}>
              <ReportingClientContext.Provider value={getReportingClient}>
                <ThemeProvider theme={SoftConfigThemeSchema.parse()}>
                  <Auth>
                    <GlobalStyle />
                    <Landing>
                      <Analytics.PageViewFromLocationPathname>
                        <Analytics.SetUserProperties>
                          <CookieBanner>
                            <UserContext.Provider value={userContext}>
                              <FlagsContextProvider>
                                <Component {...pageProps} />
                              </FlagsContextProvider>
                            </UserContext.Provider>
                          </CookieBanner>
                        </Analytics.SetUserProperties>
                      </Analytics.PageViewFromLocationPathname>
                    </Landing>
                  </Auth>
                </ThemeProvider>
              </ReportingClientContext.Provider>
            </ClientContext.Provider>
          </ConfigContext.Provider>
        </Analytics.AnalyticsContext.Provider>
      </LoggerProvider>
    </>
  )
}
