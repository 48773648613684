import * as React from 'react';
/**
 * useKeyHandler triggers an operation based on a key down & key up event, supplied key down and key up operations are optional
 * @param keyToHandle the key to handle as a string as defined in https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
 * @param keyDownOperation the operation to trigger on key down (optional - if not supplied, key down will not be handled)
 * @param keyUpOperation the operation to trigger on key up (optional - if not supplied, key up will not be handled)
 * @returns void
 */
export function useKeyHandler(keyToHandle, keyDownOperation, keyUpOperation) {
    React.useEffect(function () {
        var keyDownHandler = function (e) {
            if (e.key === keyToHandle) {
                keyDownOperation && keyDownOperation();
            }
        };
        var keyUpHandler = function (e) {
            if (e.key === keyToHandle) {
                keyUpOperation && keyUpOperation();
            }
        };
        window.addEventListener('keydown', keyDownHandler);
        window.addEventListener('keyup', keyUpHandler);
        return function () {
            window.removeEventListener('keydown', keyDownHandler);
            window.removeEventListener('keyup', keyUpHandler);
        };
    }, [keyDownOperation, keyToHandle, keyUpOperation]);
}
