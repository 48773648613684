import { Analytics } from './AnalyticsMixpanel'

export const AnalyticsConsoleLogger: Analytics = {
  setUserProperties: userId => l('setUserProperties', userId),
  pageView: page => l('pageView', page),
  signOutUser: () => l('signOutUser'),
  trackEvent: ({ name, props }) => l('trackEvent', name, props),
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, no-console
const l = (name: string, ...inputs: any[]) => console.log('analyticsConsoleLogger', name, inputs)
