/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext, useEffect, createContext } from 'react'
import { useRouter } from 'next/router'
import { useAsync } from '@tumelo/common'
import { ClientContext } from '../client'
import { AnalyticsConsoleLogger } from './AnalyticsConsoleLogger'
import { Analytics } from './AnalyticsMixpanel'

export const AnalyticsContext = createContext<Analytics>(AnalyticsConsoleLogger)

export const useAnalytics = () => {
  return useContext(AnalyticsContext)
}

const useExplicitPageView = (analytics: Analytics, page: string) =>
  useEffect(() => {
    analytics.pageView(page)
  }, [analytics, page])

export const ExplicitPageView: React.FC<React.PropsWithChildren<{ page: string }>> = ({ page, children }) => {
  const analytics = useAnalytics()
  useExplicitPageView(analytics, page)
  return <>{children}</>
}

const usePageViewFromLocationPathname = () => {
  const router = useRouter()
  const analytics = useAnalytics()
  useExplicitPageView(analytics, router.pathname)
}

/**
+ * Tracks the current page being visited by the user using Next Router.
 */
export const PageViewFromLocationPathname: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  usePageViewFromLocationPathname()
  return <>{children}</>
}

/**
 * Sets the user tag
 */
export const SetUserProperties: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const getClient = useContext(ClientContext)
  const fetchUser = useAsync(async () => {
    const client = await getClient()
    return await client.GetUser({})
  })
  const analytics = useAnalytics()
  useEffect(() => {
    if (fetchUser.value) {
      analytics.setUserProperties(fetchUser.value.name)
    }
  }, [analytics, fetchUser.value])
  return <>{children}</>
}
