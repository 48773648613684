export class LoggerServiceCompound {
    constructor(...loggers) {
        this.loggers = loggers;
    }
    setUserData(u) {
        this.loggers.map((l) => l.setUserData(u));
    }
    logError(e, context) {
        this.loggers.map((l) => l.logError(e, context));
    }
}
