import * as Sentry from '@sentry/browser';
export class LoggerServiceSentry {
    constructor(init) {
        Sentry.init(Object.assign({ environment: 'development', 
            /* eslint-disable-next-line deprecation/deprecation */
            integrations: [Sentry.browserTracingIntegration()], tracesSampleRate: 0.1 }, init));
    }
    setUserData(u) {
        Sentry.setUser({
            id: u.id,
        });
    }
    logError(e, context) {
        Sentry.captureException(e, context);
    }
}
