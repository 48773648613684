import mixpanel, { Config } from 'mixpanel-browser'
import { Event } from './Event'

export interface Analytics {
  setUserProperties: (userId: string) => void
  signOutUser: () => void
  pageView: (page: string) => void
  trackEvent: (event: Event) => void
}

const PAGE_VIEW_EVENT = 'Page View'

export const createAnalyticsMixpanel = (token: string, config: Partial<Config>): Analytics => {
  mixpanel.init(token, config)

  return {
    signOutUser: () => mixpanel.reset(),
    pageView: page => mixpanel.track(PAGE_VIEW_EVENT, { page }),
    setUserProperties: userId => {
      if (userId !== '') mixpanel.identify(userId)
    },
    trackEvent: ({ name, props }) => mixpanel.track(name, props),
  }
}
